.am-card {
  min-height: 2.56rem;
  padding-bottom: 0.16rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}

.am-card:not(.am-card-full) {
  border: 1PX solid #ddd;
  border-radius: 0.133333rem;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-card:not(.am-card-full) {
    position: relative;
    border: none;
  }

  html:not([data-scale]) .am-card:not(.am-card-full)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 200%;
    height: 200%;
    border: 1PX solid #ddd;
    border-radius: 0.266667rem;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    box-sizing: border-box;
    pointer-events: none;
  }
}

.am-card.am-card-full {
  position: relative;
  border-top: 1PX solid #ddd;
  border-bottom: 1PX solid #ddd;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-card.am-card-full {
    border-top: none;
  }

  html:not([data-scale]) .am-card.am-card-full::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-card.am-card-full::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-card.am-card-full {
    border-bottom: none;
  }

  html:not([data-scale]) .am-card.am-card-full::after {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-card.am-card-full::after {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  font-size: 0.453333rem;
  padding: 0.24rem 0.4rem;
}

.am-card-header-content {
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: left;
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.am-card-header-content img {
  margin-right: 0.133333rem;
}

.am-card-header-extra {
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: right;
  font-size: 0.453333rem;
  color: #888;
}

.am-card-body {
  position: relative;
  border-top: 1PX solid #ddd;
  padding: 0.4rem 0.4rem 0.16rem;
  font-size: 0.4rem;
  color: #333;
  min-height: 1.066667rem;
  -webkit-flex: 1 1;
  flex: 1 1;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .am-card-body {
    border-top: none;
  }

  html:not([data-scale]) .am-card-body::before {
    content: '';
    position: absolute;
    background-color: #ddd;
    display: block;
    z-index: 1;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: 100%;
    height: 1PX;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 3), (min-resolution: 2dppx) and (min-resolution: 3dppx) {
  html:not([data-scale]) .am-card-body::before {
    -webkit-transform: scaleY(0.33);
    transform: scaleY(0.33);
  }
}

.am-card-footer {
  font-size: 0.32rem;
  color: #888;
  padding: 0 0.4rem;
  display: -webkit-flex;
  display: flex;
}

.am-card-footer-content {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.am-card-footer-extra {
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: right;
}